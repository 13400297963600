import React, { useMemo, useState } from 'react';
import { FormContainer } from './styledComponents';
import { FormRecord } from '~/config/generated/graphql';
import { useForm } from 'react-hook-form';
import GenericFormFieldDispatcher from './GenericFormFieldDispatcher';
import styled from 'styled-components';
import { Button, BodyText, Flex, Caption, Subtitle } from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import { nonNullable } from '~/App/shared/types/helpers';
import { postDynamicForm } from '~/helpers/orchestration/dynamicForm';
import { requestErrorHandler } from '~/helpers/notifyError';
import SuccessInfo from './SuccessInfo';
import StructuredText from '~/App/components/DatoCMS/StructuredText';
import { useSelector } from 'react-redux';
import { localizationSelector } from '~/App/shared/selectors/localization';
import { Locale } from '~/App/shared/localization/types';
import { runWithRecaptcha } from '~/helpers/recaptcha';
import { useDynamicScript } from '~/App/shared/hooks/useDynamicScript';
import { googleRecaptchaSource } from '~/config/dynamicScriptsTags';
import { getAllErrorMessages } from '~/helpers/errorUtils';
import { isEmpty } from '~/App/shared/utils';
import { headerHeightSelector } from '~/App/shared/selectors/header';
import { scrollTo } from './helpers';
import { isAxiosError } from '~/helpers/axiosUtils';

const Title = styled(Subtitle)`
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
`;

const SubmitFormDescription = styled(Caption)`
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: initial;
`;

const ErrorMessage = styled(BodyText)`
  color: ${({ theme }) => theme.colors.error.main};
  text-align: center;
`;

export type FormField = FormRecord['formFields'][number] & {
  backendKey: string;
  required: boolean;
  fieldType:
    | 'generic-textField'
    | 'generic-textArea'
    | 'generic-checkbox'
    | 'generic-multipleChoice';
  textFieldType:
    | 'text'
    | 'email'
    | 'number'
    | 'nationalIdentificationNumber'
    | 'phoneNumber';
  choiceFieldType: 'checkbox' | 'radioButton';
};

export type FormData = Record<string, string | string[]>;

type Props = {
  fields: FormRecord['formFields'];
  submitBtnText: FormRecord['formSubmitButton'];
  submitFormDescription: string;
  formInternalName: string;
  title: FormRecord['formTitle'];
  description: FormRecord['formOpenDescription'];
  successSubmissionDescription: FormRecord['formSuccessSubmissionDescription'];
};

const DynamicForm = ({
  fields,
  submitBtnText,
  formInternalName,
  submitFormDescription,
  title,
  description,
  successSubmissionDescription
}: Props) => {
  useDynamicScript(googleRecaptchaSource);
  const { t } = useTranslation();
  const { currentLocale } = useSelector(localizationSelector);
  const headerHeight = useSelector(headerHeightSelector) ?? 0;
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneralError, setIsGeneralError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { onlyValidFields } = useMemo(() => {
    const onlyValidFields = fields.filter(
      ({ backendKey, fieldType, textFieldLabel, textFieldType }) => {
        if (!backendKey || !fieldType) {
          return false;
        }
        if (
          fieldType === 'generic-textField' &&
          (!textFieldLabel || !textFieldType)
        ) {
          return false;
        }
        return true;
      }
    ) as FormField[];
    return { onlyValidFields };
  }, [fields]) as Record<string, FormField[]>;

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid }
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: onlyValidFields.reduce((acc, item) => {
      if (
        (['generic-checkbox'] as FormField['fieldType'][]).includes(
          item.fieldType
        )
      ) {
        acc[item.backendKey] = item.checkboxCheckedByDefault ?? false;
      } else if (item.fieldType === 'generic-multipleChoice') {
        const defaultChoices =
          item?.choices
            ?.map(({ label, selectedByDefault }) => {
              if (selectedByDefault) return label;
            })
            ?.filter(nonNullable) ?? [];
        acc[item.backendKey] =
          item?.choiceFieldType === 'checkbox'
            ? defaultChoices
            : defaultChoices.slice(0, 1);
      } else {
        acc[item.backendKey] = item.textFieldDefaultValue || '';
      }
      return acc;
    }, {} as FormData)
  });

  const onSubmit = (formData: FormData) => {
    setIsGeneralError(false);
    setIsLoading(true);

    runWithRecaptcha(async gRecaptchaResponse => {
      try {
        await postDynamicForm({
          formInternalName,
          formData,
          locale: currentLocale ?? Locale.SV,
          gRecaptchaResponse
        });
        setFormSubmitted(true);
      } catch (error: unknown) {
        requestErrorHandler(error);

        if (isAxiosError(error) && error?.response?.status === 400) {
          const respMessage = error?.response?.data?.message;
          if (
            respMessage &&
            typeof respMessage === 'object' &&
            !isEmpty(respMessage)
          ) {
            const fieldKeys = onlyValidFields.map(
              ({ backendKey }) => backendKey
            );

            const includedFieldKeys = Object.keys(respMessage).filter(
              errFieldKey => fieldKeys.includes(errFieldKey)
            );
            includedFieldKeys.forEach(errFieldKey =>
              setError(errFieldKey, {
                type: 'backend',
                message: getAllErrorMessages(respMessage[errFieldKey], []).join(
                  ', '
                )
              })
            );

            includedFieldKeys?.[0] &&
              scrollTo(includedFieldKeys?.[0], headerHeight);
            return;
          }
        }
        setIsGeneralError(true);
      } finally {
        setIsLoading(false);
      }
    });
  };

  if (formSubmitted) {
    return (
      <SuccessInfo title={title} description={successSubmissionDescription} />
    );
  }

  return (
    <FormContainer>
      <Title as="h3">{title}</Title>
      <StructuredText
        data={description}
        wrapper={({ children }) => <>{children}</>}
      />
      <Form onSubmit={handleSubmit(onSubmit)} id="dynamic-form" noValidate>
        {onlyValidFields.map(field => (
          <GenericFormFieldDispatcher
            key={field.backendKey}
            field={field}
            control={control}
          />
        ))}
      </Form>
      <Flex direction="column" gap={0.5}>
        {submitFormDescription && (
          <SubmitFormDescription>{submitFormDescription}</SubmitFormDescription>
        )}
        <StyledButton
          size="regular"
          type="submit"
          form="dynamic-form"
          isDisabled={!isValid}
          isLoading={isLoading}
        >
          {submitBtnText ?? t('Submit')}
        </StyledButton>
      </Flex>
      {isGeneralError && (
        <ErrorMessage>
          {t('Something went wrong, please try again later.')}
        </ErrorMessage>
      )}
    </FormContainer>
  );
};

export default DynamicForm;
